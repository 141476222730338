import * as React from "react"
import Layout from "../components/layout.js"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { StoreContext } from "../context/store-context.jsx"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { ScrollButton } from "../components/scroll-button.js"
import { NumericInput } from "../components/numeric-input.jsx"
import Star from "../icons/stars.js"
import { Stars } from "../components/stars.js"
import Checkmark from "../icons/checkmark.js"
import Popup from "../components/popup.js"
import { FaqComponent } from "../components/faq"
import PhoneWhite from "../icons/phone-white.js"
import GoogleReviewsLp from "../components/reviews-lp.js"

const LandingPage = (props) => {
    const landingpage = props.data.contentfulLandingPages
    const homepage = props.data.allContentfulHomePage
    const singleStageShopify = props.data.singleStageShopify
    const dualStageShopify = props.data.dualStageShopify
    const tripleStageShopify = props.data.tripleStageShopify
    const airbagModuleShopify = props.data.airbagModuleShopify
    const airbagPlugsShopify = props.data.airbagPlugsShopify
    const pretensionerShopify = props.data.pretensionerShopify
    const seatBeltColoringShopify = props.data.seatBeltColoringShopify
    const batteryCableShopify = props.data.batteryCableShopify
    const collapsibleSteeringShopify = props.data.collapsibleSteeringShopify
    const convertibleRollBarShopify = props.data.convertibleRollBarShopify
    let buttonDestination = '/'
    let dataModel = singleStageShopify
    let reviewNumbers = '550'
    let variantId = 'gid://shopify/ProductVariant/42587634270463'

    if (landingpage.category === 'Seat Belt Repair') {
        buttonDestination = '/services'
        dataModel = singleStageShopify
        reviewNumbers = '550'
        variantId = 'gid://shopify/ProductVariant/42587634270463'
    }
    else if (landingpage.category === 'Airbag Module Repair') {
        buttonDestination = '/services/srs-airbag-module-repair-service/airbag-module-reset'
        dataModel = airbagModuleShopify
        reviewNumbers = '774'
        variantId = 'gid://shopify/ProductVariant/45416717320447'
    }
    else if (landingpage.category === 'Airbag Plugs') {
        buttonDestination = '/services/airbag-plugs/airbag-plugs/'
        dataModel = airbagPlugsShopify
        reviewNumbers = '137'
        variantId = 'gid://shopify/ProductVariant/46068179632383'
    }
    else if (landingpage.category === 'Pretensioner Repair') {
        buttonDestination = '/services/seat-belt-repair-service/pretensioner'
        dataModel = pretensionerShopify
        reviewNumbers = '654'
        variantId = 'gid://shopify/ProductVariant/42583487283455'
    }
    else if (landingpage.category === 'Seat Belt Coloring') {
        buttonDestination = '/services/seat-belt-coloring-service/seat-belt-coloring'
        dataModel = seatBeltColoringShopify
        reviewNumbers = '200'
    }
    else if (landingpage.category === 'Battery Cable Repair') {
        buttonDestination = '/services/battery-cable-repair-service/battery-cable'
        dataModel = batteryCableShopify
        reviewNumbers = '137'
        variantId = 'gid://shopify/ProductVariant/45416602435839'
    }
    else if (landingpage.category === 'Steering Column Sensor Repair') {
        buttonDestination = '/services/steering-column-sensor-repair-service/collapsible-steering-sensor'
        dataModel = collapsibleSteeringShopify
        reviewNumbers = '180'
        variantId = 'gid://shopify/ProductVariant/45416713322751'
    }
    else if (landingpage.category === 'Convertible Roll Bar Repair') {
        buttonDestination = '/services/convertible-roll-bar-repair-service/convertible-roll-bar'
        dataModel = convertibleRollBarShopify
        reviewNumbers = '309'
        variantId = 'gid://shopify/ProductVariant/45416719941887'
    }
    else {
        buttonDestination = '/'
        dataModel = singleStageShopify
        reviewNumbers = '550'
        variantId = 'gid://shopify/ProductVariant/42587634270463'
    }

    const { addVariantToCart, loading } = React.useContext(StoreContext)

    function addToCart(variantId, quantity, IsSelected, hasVariants) {
        addVariantToCart(variantId, quantity)
    }

    var [quantitySuggested, setQuantitySuggested] = React.useState(1)

    return (
        <Layout>
            <Seo
                LandingPageData={landingpage}
                title={landingpage.section1Title + " - Post Collision Specialists"}
                location={landingpage.slug}
                keywords={[landingpage.category]}
                description={'Explore L&D Solutions dedicated landing pages for post-collision auto repair services, focusing on ' + landingpage.section1Title + '. Each page is designed to highlight specific services, provide essential information, and guide customers toward solutions they can trust.'}
                isLandingPage={true}
                img={getSrc(landingpage.section1Image.gatsbyImageData)}
                landingPagePrice={'$' + Number(dataModel.priceRangeV2.maxVariantPrice.amount).toFixed(2)}
                reviewsCode = {homepage.edges[0].node.embeddedReviewsCode.embeddedReviewsCode}
            />
            {/* Start Top Bar */}
            <div className="bg-black pt-5 pb-4 lg:block px-5">
                <div className="container mx-auto text-xs md:text-base">
                    <div className="text-white">
                        <ul className="flex justify-center space-x-1 md:space-x-2 font-semibold">
                            <li className="flex pt-1 md:pt-1.5"><Star /><Star /><Star /><Star /><Star /></li>
                            <li className="flex">{landingpage.topBannerText.topBannerText}</li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* End Top Bar */}

            <div className="container mx-auto">
                {/* Start Main Section */}
                <div className="rounded-lg z-10">
                    <div className="lg:flex">
                        <div className='mx-auto px-5 py-10 pt-24 lg:top-8'>
                            <h1 className="font-bold text-5xl md:text-4xl mx-auto max-w-md lg:text-5xl text-center">
                                {landingpage.section1Title}
                            </h1>

                            <div className="mx-auto flex justify-center">
                                <ul className="font-semibold text-lg pt-10 space-y-2">
                                    {landingpage.section1List.map((list, index) => (
                                        <li key={index} className="flex"><Checkmark />{list}</li>
                                    ))}
                                </ul>
                            </div>

                            {landingpage.scope ? (
                                <div className="mx-auto flex justify-center pt-5">
                                    <button className="m-5 relative h-10 py-2 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out" title='Call L&D Solutions' alt='Call L&D Solutions'>
                                        <a href="tel:916-751-8020" className="flex gap-x-2">
                                            <PhoneWhite /> (916) 751-8020
                                        </a>
                                    </button>
                                </div>
                            ) : (
                                <div className="mx-auto flex justify-center pt-5"><ScrollButton buttonText={landingpage.section1ButtonText} buttonPage={buttonDestination} title={landingpage.section1ButtonText} alt={landingpage.section1ButtonText} /></div>
                            )}

                            <div className="pt-5 text-lg max-w-md mx-auto text-center" dangerouslySetInnerHTML={{
                                __html: landingpage.section1Body.childMarkdownRemark.html,
                            }} />

                        </div>

                        <div className="hidden lg:block">
                            <GatsbyImage image={landingpage.section1Image.gatsbyImageData} className='max-w-[700px]' loading="eager" placeholder="none" title={landingpage.section1Image.title} alt={landingpage.section1Image.description} />
                        </div>

                        <div className="mx-auto flex justify-center lg:hidden">
                            <GatsbyImage image={landingpage.section1Image.gatsbyImageData} className='w-[350px] md:w-[450px]' loading="eager" placeholder="none" title={landingpage.section1Image.title} alt={landingpage.section1Image.description} />
                        </div>

                    </div>
                </div>

                {/* End Main Section */}

                {/* Start Banner 1*/}
                <div className="container mx-auto mb-10">
                    <div className="grid sm:grid-cols-2 xl:grid-cols-4 px-10 pt-14 pb-8 mx-auto xl:flex xl:flex-row items-start p-0 gap-8 w-full sm:justify-center">

                        <div className="flex flex-row items-start p-0 gap-2 max-w-xl">
                            <div className="pt-4 lg:mr-2">
                                <GatsbyImage image={landingpage.banner1Icon1.gatsbyImageData} className="w-10" loading="eager" placeholder="none" title={landingpage.banner1Icon1.title} alt={landingpage.banner1Icon1.description} />
                            </div>
                            <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                <div className="font-semibold text-lg leading-6 flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.banner1Title1}
                                </div>
                                <div className="max-w-xl text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.banner1Body1.banner1Body1}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row items-start p-0 gap-2 max-w-xl">
                            <div className="pt-4 lg:mr-2">
                                <GatsbyImage image={landingpage.banner1Icon2.gatsbyImageData} className="w-10" loading="eager" placeholder="none" title={landingpage.banner1Icon2.title} alt={landingpage.banner1Icon2.description} />
                            </div>
                            <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                <div className="font-semibold text-lg leading-6 flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.banner1TItle2}
                                </div>
                                <div className="max-w-xl text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.banner1Body2.banner1Body2}
                                </div>
                            </div>
                        </div>

                        <div className={`flex flex-row items-start p-0 gap-2 max-w-xl ${landingpage.banner1Title3.toLowerCase() === 'none' ? 'hidden' : ''}`}>
                            <GatsbyImage image={landingpage.banner1Icon3.gatsbyImageData} className="w-10" loading="eager" placeholder="none" title={landingpage.banner1Icon3.title} alt={landingpage.banner1Icon3.description} />
                            <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                <div className="font-semibold text-lg leading-6 flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.banner1Title3}
                                </div>
                                <div className="max-w-xl text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.banner1Body3.banner1Body3}
                                </div>
                            </div>
                        </div>

                        <div className={`flex flex-row items-start p-0 gap-2 max-w-xl ${landingpage.banner1Title4.toLowerCase() === 'none' ? 'hidden' : ''}`}>
                            <GatsbyImage image={landingpage.banner1Icon4.gatsbyImageData} className="w-10" loading="eager" placeholder="none" title={landingpage.banner1Icon4.title} alt={landingpage.banner1Icon4.description} />
                            <div className="flex flex-col items-start p-0 gap-2 w-[186.4px] h-auto flex-none order-1 flex-grow">
                                <div className="font-semibold text-lg leading-6 flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.banner1Title4}
                                </div>
                                <div className="max-w-xl text-sm leading-4 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.banner1Body4.banner1Body4}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* End Banner 1*/}

                {/* Start Section 2 */}
                <div className="px-5">
                    <h2 className="font-bold text-3xl text-center mx-auto">{landingpage.section2Title}</h2>
                    <div className="rounded-lg z-10">
                        <div className="lg:flex">
                            <div className="hidden lg:flex 2xl:translate-x-[100px]">
                                <GatsbyImage image={landingpage.section2Image.gatsbyImageData} className='max-w-[700px]' loading="eager" placeholder="none" title={landingpage.section2Image.title} alt={landingpage.section2Image.description} />
                            </div>
                            <div className="lg:hidden">
                                <div className="mx-auto flex justify-center">
                                    <GatsbyImage image={landingpage.section2Image.gatsbyImageData} className='w-[350px] md:w-[550px]' loading="eager" placeholder="none" title={landingpage.section2Image.title} alt={landingpage.section2Image.description} />
                                </div>
                            </div>
                            <div className='mx-auto px-5 py-5 lg:pt-24'>
                                <div className="lg:pt-5 text-lg md:max-w-3xl 2xl:max-w-md mx-auto text-center leading-10" dangerouslySetInnerHTML={{
                                    __html: landingpage.section2Body.childMarkdownRemark.html,
                                }} />
                                <div className="mx-auto flex justify-center pt-5"><ScrollButton buttonText='Contact Us' buttonPage='/contact' /></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Section 2 */}

                {/* Start Banner 2 */}
                <div className="container mx-auto mb-10">
                    <h3 className="font-bold text-3xl lg:text-4xl text-center pt-16 max-w-lg mx-auto">{landingpage.section3TItle}</h3>

                    <div className="grid md:grid-cols-3 px-10 pt-5 pb-8 mx-auto xl:flex xl:flex-row items-start p-0 gap-8 w-full sm:justify-center">

                        <div className="items-start p-0 gap-2 max-w-xl">
                            <div className="pt-4 pb-4 flex justify-center">
                                <GatsbyImage image={landingpage.section3BannerIcon1.gatsbyImageData} className="w-16" loading="eager" placeholder="none" title={landingpage.section3BannerIcon1.title} alt={landingpage.section3BannerIcon1.description} />
                            </div>
                            <div className="flex flex-col items-start text-center p-0 gap-2 flex-none order-1 flex-grow">
                                <div className="font-bold text-xl flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.section3BannerTitle1}
                                </div>
                                <div className="max-w-sm mx-auto leading-8 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.section3BannerBody1.section3BannerBody1}
                                </div>
                            </div>
                        </div>

                        <div className="items-start p-0 gap-2 max-w-xl">
                            <div className="pt-4 pb-4 flex justify-center">
                                <GatsbyImage image={landingpage.section3BannerIcon2.gatsbyImageData} className="w-16" loading="eager" placeholder="none" title={landingpage.section3BannerIcon2.title} alt={landingpage.section3BannerIcon2.description} />
                            </div>
                            <div className="flex flex-col items-start text-center p-0 gap-2 flex-none order-1 flex-grow">
                                <div className="font-bold text-xl flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.section3BannerTitle2}
                                </div>
                                <div className="max-w-sm mx-auto leading-8 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.section3BannerBody2.section3BannerBody2}
                                </div>
                            </div>
                        </div>

                        <div className="items-start p-0 gap-2 max-w-xl">
                            <div className="pt-4 pb-4 flex justify-center">
                                <GatsbyImage image={landingpage.section3BannerIcon3.gatsbyImageData} className="w-16" loading="eager" placeholder="none" title={landingpage.section3BannerIcon3.title} alt={landingpage.section3BannerIcon3.description} />
                            </div>
                            <div className="flex flex-col items-start text-center p-0 gap-2 flex-none order-1 flex-grow">
                                <div className="font-bold text-xl flex-none order-0 self-stretch flex-grow-0">
                                    {landingpage.section3BannerTitle3}
                                </div>
                                <div className="max-w-sm mx-auto leading-8 tracking-wide flex-none order-1 self-stretch flex-grow-0">
                                    {landingpage.section3BannerBody3.section3BannerBody3}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={`${landingpage.scope ? 'pt-10' : 'py-10'}`}>
                        <div className="text-lndred text-center text-3xl lg:text-4xl font-bold max-w-md mx-auto" dangerouslySetInnerHTML={{
                            __html: landingpage.section3Address.childMarkdownRemark.html,
                        }} />
                        {landingpage.scope ? (
                            <div className="pt-10">
                                <div className="flex justify-center px-10 md:w-[550px] xl:w-[800px] mx-auto">
                                    <a href={landingpage.mapUrl.mapUrl} className='hover:text-lndred transition duration-150 ease-in-out' target="blank" rel="noreferrer" title={landingpage.mapUrl.title} alt={landingpage.mapUrl.description}>
                                        <GatsbyImage image={landingpage.map.gatsbyImageData} className="hover:border hover:border-lndred hover:rounded-lg transition duration-150 ease-in-out rounded-lg" title="L&D Solutions Repairs" alt="L&D Solutions Repairs And Services" loading="eager" placeholder="none" />
                                    </a>
                                </div>

                                <div className="mx-auto flex justify-center pt-5">
                                    <button className="m-5 relative h-10 w-48 py-2 pb-14 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out text-left">
                                        <a href="tel:916-751-8020" title='Call L&D Solutions' alt='Call L&D Solutions'>
                                            <div className="flex space-x-2">
                                                <div className="pt-3">
                                                    <PhoneWhite />
                                                </div>
                                                <span>Contact Us<br />916-751-8020</span>
                                            </div>
                                        </a>
                                    </button>
                                </div>

                            </div>

                        ) : (
                            <div className="mx-auto flex justify-center"> <ScrollButton buttonText={landingpage.section3ButtonText} buttonPage={buttonDestination} /></div>
                        )}
                        <div className={`text-center mx-auto text-xl lg:text-2xl px-5 ${landingpage.scope ? 'pt-10' : 'py-10'}`} dangerouslySetInnerHTML={{
                            __html: landingpage.section3Body.childMarkdownRemark.html,
                        }} />
                    </div>

                </div>
                {/* End Banner 2 */}

                {/* Start Add To Cart Section */}
                {!landingpage.scope ? (
                    <div>
                        <div className="px-5">
                            <h2 className="font-bold text-4xl text-center mx-auto pb-10">{landingpage.section4Title}</h2>
                            <div className="rounded-lg z-10">
                                <div className="lg:flex mx-auto justify-center lg:space-x-10">
                                    <div className="hidden lg:flex border rounded-xl">
                                        <GatsbyImage image={dataModel.featuredImage.gatsbyImageData} className='max-w-[550px] mx-auto' loading="eager" placeholder="none" title={dataModel.featuredImage.altText} alt={dataModel.featuredImage.altText} />
                                    </div>
                                    <div className="lg:hidden border rounded-xl">
                                        <div className="mx-auto flex justify-center">
                                            <GatsbyImage image={dataModel.featuredImage.gatsbyImageData} className='' loading="eager" placeholder="none" title={dataModel.featuredImage.altText} alt={dataModel.featuredImage.altText} />
                                        </div>
                                    </div>

                                    <div className="">
                                        <div className="text-2xl pb-2 pt-7 lg:pt-0">{dataModel.title}</div>
                                        <div className="flex text-xs md:text-base pt-2 lg:pt-0">
                                            <div>
                                                <Stars reviewNumber={reviewNumbers} />
                                            </div>
                                            <div className="lg:hidden -ml-3">
                                                <Popup />
                                            </div>
                                        </div>

                                        <p className="font-bold pt-5 text-3xl">${Number(dataModel.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>

                                        <div className="lg:max-w-md mx-auto leading-8 mt-5" dangerouslySetInnerHTML={{
                                            __html: landingpage.section4Body.childMarkdownRemark.html,
                                        }} />

                                        <div className="mx-auto flex justify-center pt-5 space-x-5">

                                            <div className="text-sm">
                                                <NumericInput
                                                    aria-label="Quantity"
                                                    onIncrement={() => setQuantitySuggested((q) => Math.min(q + 1, 100))}
                                                    onDecrement={() => setQuantitySuggested((q) => Math.max(1, q - 1))}
                                                    onChange={(event) => setQuantitySuggested(event.currentTarget.value)}
                                                    value={quantitySuggested}
                                                    min="1"
                                                    max="100"
                                                />
                                            </div>

                                            {landingpage.category === 'Seat Belt Coloring' ? (
                                                <Link to='/services/seat-belt-coloring-service/seat-belt-coloring/' className='mt-2 relative text-xs md:text-sm py-2 px-5 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                    <span>{landingpage.section4ButtonText}</span>
                                                </Link>
                                            ) : (
                                                <button onClick={() => { addToCart(variantId, quantitySuggested, true, false) }}
                                                    className='mt-2 relative text-xs md:text-sm py-2 px-5 text-sm rounded-lg border border-lndred bg-lndred text-white hover:shadow-lg hover:shadow-lndred focus:shadow-outline font-semibold transition duration-150 ease-in-out'>
                                                    <span>{landingpage.section4ButtonText}</span>
                                                </button>
                                            )}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container mx-auto pt-24">
                            <div className="px-5 pt-5 pb-20 xl:px-52 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-center gap-9 md:gap-5">

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Single Stage Seat Belt Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/seat-belt-repair-service/single-stage-seat-belts">
                                        <GatsbyImage image={singleStageShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={singleStageShopify.featuredImage.altText} alt={singleStageShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{singleStageShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(singleStageShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/42587634270463', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Dual Stage Seat Belt Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/seat-belt-repair-service/dual-stage-seat-belt">
                                        <GatsbyImage image={dualStageShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={dualStageShopify.featuredImage.altText} alt={dualStageShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{dualStageShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(dualStageShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583355490559', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Triple Stage Seat Belt Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/seat-belt-repair-service/triple-stage-seat-belt">
                                        <GatsbyImage image={tripleStageShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={tripleStageShopify.featuredImage.altText} alt={tripleStageShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{tripleStageShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(tripleStageShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583368990975', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Airbag Module Reset') ? '' : 'hidden'}`}>
                                    <Link to="/services/srs-airbag-module-repair-service/airbag-module-reset">
                                        <GatsbyImage image={airbagModuleShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={airbagModuleShopify.featuredImage.altText} alt={airbagModuleShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{airbagModuleShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(airbagModuleShopify.priceRangeV2.minVariantPrice.amount).toFixed(2)} - ${Number(airbagModuleShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416717320447', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Airbag Plugs') ? '' : 'hidden'}`}>
                                    <Link to="/services/airbag-plugs/airbag-plugs/">
                                        <GatsbyImage image={airbagPlugsShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={airbagPlugsShopify.featuredImage.altText} alt={airbagPlugsShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{airbagPlugsShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(airbagPlugsShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/46068179632383', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Pretensioner Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/seat-belt-repair-service/pretensioner">
                                        <GatsbyImage image={pretensionerShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={pretensionerShopify.featuredImage.altText} alt={pretensionerShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{pretensionerShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(pretensionerShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/42583487283455', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Battery Cable Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/battery-cable-repair-service/battery-cable">
                                        <GatsbyImage image={batteryCableShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={batteryCableShopify.featuredImage.altText} alt={batteryCableShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{batteryCableShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(batteryCableShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416602435839', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Collapsible Steering Sensor Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/steering-column-sensor-repair-service/collapsible-steering-sensor">
                                        <GatsbyImage image={collapsibleSteeringShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={collapsibleSteeringShopify.featuredImage.altText} alt={collapsibleSteeringShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{collapsibleSteeringShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(collapsibleSteeringShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416713322751', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Convertible Roll Bar Repair') ? '' : 'hidden'}`}>
                                    <Link to="/services/convertible-roll-bar-repair-service/convertible-roll-bar">
                                        <GatsbyImage image={convertibleRollBarShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={convertibleRollBarShopify.featuredImage.altText} alt={convertibleRollBarShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{convertibleRollBarShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(convertibleRollBarShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <button onClick={() => { addToCart('gid://shopify/ProductVariant/45416719941887', '1', true, false) }}>
                                                        <CartIcon />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`max-w-sm mx-auto border shadow-xl rounded-lg bg-transparent hover:border-lndred transition duration-150 ease-in-out ${landingpage.servicesToShowForPurchase.includes('Seat Belt Coloring') ? '' : 'hidden'}`}>
                                    <Link to="/services/seat-belt-coloring-service/seat-belt-coloring/">
                                        <GatsbyImage image={seatBeltColoringShopify.featuredImage.gatsbyImageData} placeholder="none" className="rounded-t-lg h-72 z-0 bg-white" title={seatBeltColoringShopify.featuredImage.altText} alt={seatBeltColoringShopify.featuredImage.altText} />
                                    </Link>
                                    <div className="px-2">
                                        <div className="h-36">
                                            <h3 className="text-lg pt-5 text-left px-5">{seatBeltColoringShopify.title}</h3>
                                            <div className="flex justify-between px-5">
                                                <div>
                                                    <p className="font-bold pt-5">${Number(seatBeltColoringShopify.priceRangeV2.maxVariantPrice.amount).toFixed(2)}</p>
                                                    <div className="flex">
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                        <Star />
                                                    </div>
                                                </div>

                                                <div className="pt-3">
                                                    <Link to="/services/seat-belt-coloring-service/seat-belt-coloring/">
                                                        <CartIcon />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                ) : (
                    <></>
                )}
                {/* End Add To Cart Section */}

                {/*Start Reviews Section */}
                <GoogleReviewsLp reviewsCode={homepage.edges[0].node.embeddedReviewsCode.embeddedReviewsCode}/>
                {/*End Reviews Section */}

                {/* Start FAQ Section */}
                <div>
                    <p className="text-center font-bold text-3xl">{landingpage.faqTitle}</p>
                    <p className="text-center py-4">{landingpage.faqSubtitle}</p>
                    <div className="xl:flex items-center justify-center xl:translate-x-[-75px]">
                        <FaqComponent faq={landingpage.faq} hideTitle={true} />

                        <div className="xl:translate-x-[-200px] flex justify-center pb-8 translate-y-[-30px] xl:translate-y-[0px]">
                            <button className="m-5 relative h-10 w-48 py-2 pb-14 px-5 mx-auto rounded-lg border border-lndred focus:shadow-outline bg-lndred text-white hover:shadow-lg hover:shadow-lndred font-semibold transition duration-150 ease-in-out text-left">
                                <a href="tel:916-751-8020" title='Call L&D Solutions' alt='Call L&D Solutions'>
                                    <div className="flex space-x-2">
                                        <div className="pt-3">
                                            <PhoneWhite />
                                        </div>
                                        <span>Contact Us<br />916-751-8020</span>
                                    </div>
                                </a>
                            </button>
                        </div>

                    </div>
                </div>
                {/* End FAQ Section */}

            </div>
        </Layout>
    )
}
export default LandingPage

function CartIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            viewBox="0 0 44 44"
            style={{ transition: "transform 0.3s ease-in-out" }}
            className="cart-icon"
        >
            {/* Outer Circle */}
            <circle
                cx="22"
                cy="22"
                r="21"
                fill="#FF5A30"
                stroke="black"
                strokeWidth="1"
                className="circle"
                style={{
                    transition: "fill 0.3s ease, stroke 0.3s ease",
                }}
            />
            {/* Hollow Cart Icon */}
            <g transform="translate(13 14) scale(0.55)">
                <path
                    d="M7 20c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm18 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-20-4h18.3c.65 0 1.22-.35 1.5-.9l4-7.5c.15-.25.2-.55.15-.85a1 1 0 0 0-.95-.75H7.2L6.1 3.65A1 1 0 0 0 5.2 3H2v2h2.6l4.5 12H6v2h2c.6 0 1.12-.34 1.4-.85L8.5 18zm0 0"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    className="cart"
                    style={{
                        transition: "stroke 0.3s ease",
                    }}
                />
            </g>

            <style jsx>{`
          .cart-icon:hover {
            transform: scale(1.1);
          }
  
          .cart-icon:hover .circle {
            fill: white;
            stroke: black;
          }
  
          .cart-icon:hover .cart {
            stroke: black;
          }
        `}</style>
        </svg>
    )
}

export const pageQuery = graphql`
query ContentfulLandingPagesBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    singleStageShopify: shopifyProduct(id: {eq: "09feafda-b834-53b4-bc95-ebafb030a5cb"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }
  }
  dualStageShopify: shopifyProduct(id: {eq: "035fee0a-72d3-5180-9f89-1e5ce9136608"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }
  tripleStageShopify: shopifyProduct(id: {eq: "7bf4d734-1e61-5ca7-96aa-5ff2ebba3fcc"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  } 
  airbagModuleShopify: shopifyProduct(id: {eq: "c9b70add-b2f8-5553-99b2-fe8c3c546bb2"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
        minVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }         
  airbagPlugsShopify: shopifyProduct(id: {eq: "793aa045-f723-5b06-8a88-522934608dab"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }       
  pretensionerShopify: shopifyProduct(id: {eq: "8b0491d6-b1e0-5f73-8f36-4cd96fba6f56"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }      
  seatBeltColoringShopify: shopifyProduct(id: {eq: "4e3c1dfe-0f97-568d-92a3-e2b23518404d"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }      
    batteryCableShopify: shopifyProduct(id: {eq: "ef15f1f7-8022-5b0f-8195-aa9d33ab5768"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }      
  collapsibleSteeringShopify: shopifyProduct(id: {eq: "f1b8180f-548f-5dc4-944b-7a8c4c49405d"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }       
  convertibleRollBarShopify: shopifyProduct(id: {eq: "6985fcfa-c311-59fd-a4c1-8e0723a790b0"}) {
    title
    priceRangeV2 {
        maxVariantPrice {
            amount
        }
    }
    featuredImage {
      gatsbyImageData(placeholder: NONE)
      altText
    }    
  }                 
    allContentfulSingleStageSeatBeltRepairService {
        edges {
            node {
                image {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                  description
                  title
                }
            }
        }
    }
    allContentfulHomePage {
        edges {
            node {
                embeddedReviewsCode {
                    embeddedReviewsCode
                }
            }
        }
    }    
    contentfulLandingPages( slug: {eq: $slug }) {
        scope
        category
        slug
        topBannerText {
          topBannerText
        }
        section1Title
        section1List
        section1ButtonText
        section1Body {
            childMarkdownRemark {
                html
            }
        }
        section1Image {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }
        banner1Icon1 {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }
        banner1Title1
        banner1Body1 {
            banner1Body1
        }
        banner1Icon2 {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }    
        banner1TItle2
        banner1Body2 {
            banner1Body2
        }
        banner1Icon3 {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }    
        banner1Title3
        banner1Body3 {
            banner1Body3
        }
        banner1Icon4 {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }    
        banner1Title4
        banner1Body4 {
            banner1Body4
        }
        section2Title
        section2Body {
            childMarkdownRemark {
                html
            }
        }
        section2Image {
            gatsbyImageData(placeholder: NONE)
            title
            description
        }
        section3TItle
        section3BannerIcon1 {
          gatsbyImageData(placeholder: NONE)
          title
          description
        }
        section3BannerIcon2 {
          gatsbyImageData(placeholder: NONE)
          title
          description
        }
        section3BannerIcon3 {
          gatsbyImageData(placeholder: NONE)
          title
          description
        }
        section3BannerTitle1
        section3BannerBody1 {
            section3BannerBody1
        }
        section3BannerTitle2
        section3BannerBody2 {
            section3BannerBody2
        }
        section3BannerTitle3
        section3BannerBody3 {
            section3BannerBody3
        }
        map {
          gatsbyImageData(placeholder: NONE)
          title
          description
        }
        mapUrl {
          mapUrl
        }  
        section3Address {
            childMarkdownRemark {
                html
            }
        }
        section3ButtonText
        section3Body {
            childMarkdownRemark {
                html
            }
        }
        section4Title
        section4Body {
            childMarkdownRemark {
                html
            }
        }
        section4ButtonText
        reviews {
            id
            title
            review
            rating
        }
        servicesToShowForPurchase
        faqTitle
        faqSubtitle        
        faq {
            question
            answer
        }
    }
}
`